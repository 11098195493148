<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">绩效填报审核</h2>
        <div>
          <el-form class="twoForm" :inline="true">
            <el-form-item label="时间：" class="timClas">
              <el-date-picker
                  v-model="startTime"
                  type="month"
                  format="yyyy 年 MM 月"
                  value-format="yyyy-MM"
                  @change="changestartTime"
                  placeholder="选择时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="科室：" v-if="deptId=='101'">
              <el-select v-model="typeValue" placeholder="请选择科室" clearable filterable @change="typeValueChange(typeValue)" class="xiangType">
                <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-table
            :data="DeplisttableData"
            border
            center
        >
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column
              prop="basePeriodTime"
              label="时间"
              align="center"
          >
          </el-table-column>
          <el-table-column prop="name" label="科室" align="center">
          </el-table-column>
          <el-table-column prop="outCheckIncome" label="外院化验收入" align="center" width="110">
          </el-table-column>
          <el-table-column prop="outCheckExpend" label="外院化验支出" align="center" width="110">
          </el-table-column>
          <el-table-column prop="nightDutyAllowance" label="夜班费" align="center">
          </el-table-column>
          <el-table-column prop="painfulDelivery" label="阵痛分娩奖励" align="center" width="110">
          </el-table-column>
          <el-table-column prop="qualityControl" label="质控奖罚" align="center">
          </el-table-column>
          <el-table-column prop="cancerScreen" label="两癌筛查" align="center">
          </el-table-column>
          <el-table-column prop="medicalInsuranceFund" label="医保基金" align="center">
          </el-table-column>
          <el-table-column prop="bonusSubsidy" label="奖金补助" align="center">
          </el-table-column>
          <el-table-column prop="otherSpecial" label="体检专项(体检科)" align="center">
          </el-table-column>
          <el-table-column prop="personnelExpenditure" label="人员列支" align="center">
          </el-table-column>
          <el-table-column prop="checkup" label="体检专项" align="center">
          </el-table-column>
          <el-table-column prop="other" label="其他" align="center">
          </el-table-column>
          <el-table-column label="审核状态" align="center">
<!--            '夜班费填报状态（各科室自行填报）0 待填写 1待审核 2审核通过  3审核失败'-->
            <template slot-scope="scope">
              <div style="color: #81D3F8" v-if="scope.row.status7=='0'">待填写</div>
              <div style="color: #FACD91" v-if="scope.row.status7=='1'">待审核</div>
              <div style="color: #039B13" v-else-if="scope.row.status7=='2'">审核通过</div>
              <div style="color: #D9001B" v-else-if="scope.row.status7=='3'">审核失败</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="210">
            <template style="display: flex" slot-scope="scope">
              <!--审核通过显示查看   审核失败显示查看-->
              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.status7=='2'||scope.row.status7=='3'"
                  @click="lookClick(scope.row)"
              >查看</el-button>

              <!--待审核显示编辑、通过、驳回-->
              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.status7=='1'"
                  @click="fillClick(scope.row)"
              >编辑</el-button>
              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.status7=='1'"
                  @click="refillClick(scope.row)"
              >通过</el-button>
              <el-button
                  size="mini"
                  type="warning"
                  v-if="scope.row.status7=='1'"
                  @click="rejectClick(scope.row)"
              >驳回</el-button>

              <!--审核失败显示驳回原因-->
              <el-button
                  size="mini"
                  type="warning"
                  v-if="scope.row.status7=='3'"
                  @click="reasonClick(scope.row)"
              >查看驳回原因</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <el-dialog
        :title="fillTitle"
        :visible.sync="FillingVisible"
        width="600px"
    >
      <el-form
          :model="Performance"
          ref="PerformanceRef"
          :rules="PerformanceRules"
          :inline="true"
          label-width="120px"
      >
        <el-form-item prop="refexlabt" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              外院化验收入
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.refexlabt"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="exlabexp" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              外院化验支出
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.exlabexp"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="nightVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              夜班费
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.nightVal"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="painreVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              阵痛分娩奖励
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.painreVal"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="qucopeVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              质控奖罚
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.qucopeVal"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="twoCanVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              两癌筛查
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.twoCanVal"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="medifuVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              医保基金
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.medifuVal"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="bonsuVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              奖金补助
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.bonsuVal"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="otherSpecial" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              体检专项(体检科)
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.otherSpecial"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="perexpVal" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              人员列支
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.perexpVal"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="checkup" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              体检专项
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.checkup"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="other" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              其他
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input type="number" :disabled="disabled" v-model="Performance.other"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item v-if="btnShow">
          <div class="btnSize">
          <el-button type="primary" @click="onsubmit"
          >提交数据</el-button
          >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 驳回 -->
    <el-dialog
        :title="rejectTitle"
        :visible.sync="rejectVisible"
        :before-close="rejectVisibleClose"
        width="510px"
    >
      <el-checkbox-group :disabled="checkDisab" v-model="checkedCities" @change="handleCheckedCitiesChange" class="checRev">
        <el-checkbox v-for="reje in rejectOption" :label="reje" :key="reje">{{reje}}</el-checkbox>
      </el-checkbox-group>
      <div class="btnSize" v-if="!checkDisab">
        <el-button type="primary" @click="checSubmit">确定</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";

export default {
  data() {
    return {
      deptId:window.sessionStorage.getItem("deptId"),
      checkDisab:false,
      checkedCities: [],
      rejectOption:  [],
      rejectTitle:'',
      typeValue:"",
      depoptions:[],
      startTime:'',
      endTime:"",
      disabled:false,
      btnShow:true,
      fillTitle:'',
      // 填报
      Performance: {
        nightVal:'',//夜班费
        twoCanVal:"",//两癌筛查
        medifuVal:"",//医保基金
        bonsuVal:"",//奖金补助
        otherSpecial:"",//体检专项(体检科)
        perexpVal:"",//人员列支
        checkup:"",//体检专项
        painreVal:"",//阵痛分娩奖励
        qucopeVal:"",//质控奖罚
        exlabexp:"",//外院化验支出
        refexlabt:"",//外院化验收入
        other:""
      },
      // 绩效分配结构规则
      PerformanceRules: {
        exlabexp: [
          {
            required: true,
            message: "请输入外院化验支出",
            trigger: "blur",
          },
        ],
        other: [
          {
            required: true,
            message: "请输入其他",
            trigger: "blur",
          },
        ],
        refexlabt: [
          {
            required: true,
            message: "请输入外院化验收入",
            trigger: "blur",
          },
        ],
        qucopeVal: [
          {
            required: true,
            message: "请输入质控奖罚",
            trigger: "blur",
          },
        ],
        painreVal: [
          {
            required: true,
            message: "请输入阵痛分娩奖励",
            trigger: "blur",
          },
        ],
        perexpVal: [
          {
            required: true,
            message: "请输入人员列支",
            trigger: "blur",
          },
        ],
        checkup: [
          {
            required: true,
            message: "请输入体检专项",
            trigger: "blur",
          },
        ],
        otherSpecial: [
          {
            required: true,
            message: "请输入体检专项(体检科)",
            trigger: "blur",
          },
        ],
        bonsuVal: [
          {
            required: true,
            message: "请输入奖金补助",
            trigger: "blur",
          },
        ],
        medifuVal: [
          {
            required: true,
            message: "请输入医保基金",
            trigger: "blur",
          },
        ],
        nightVal: [
          {
            required: true,
            message: "请输入夜班费",
            trigger: "blur",
          },
        ],
        twoCanVal: [
          {
            required: true,
            message: "请输入两癌筛查",
            trigger: "blur",
          },
        ],
      },
      // 填报弹出框
      FillingVisible: false,
      rejectVisible:false,
      // 表格数据
      DeplisttableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 总页数
      total: 1,
      // 每页页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      bascId:"",
      basbasePeriodTime:"",
      basStatus:"",
      rejName:""
    };
  },
  created() {
    this.getDateTime();
    // 科室数据查询
    this.findDepartment()
  },
  methods: {
    rejectVisibleClose(){
      this.rejectVisible=false
      this.checkDisab=false
      this.rejName=''
    },
    typeValueChange(val){
      this.typeValue=val
      this.getDepartmentNotPage();
    },
    async findDepartment(){
      let data = {
        hospitalId:window.sessionStorage.getItem("hospitalId")
      };
      let { data: res } = await this.$axios.findDepartment(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.depoptions = res.data;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async getDateTime(){
      let { data: res } = await this.$axios.getDateTime();
      // console.log(res);
      this.startTime=res
      this.getDepartmentNotPage();
    },
    changestartTime(val){
        this.startTime=val
        this.getDepartmentNotPage();
    },
    handleCheckedCitiesChange(value) {
      if(value){
        this.checkedCities=value
      }
    },
    async checSubmit(){
      if(this.checkedCities.length>0){
        let data = {
          name:this.rejName,
          id: this.bascId, //id
          reasonForRejection: this.checkedCities.join(','),//驳回内容
        };
        let { data: res } = await this.$axios.reject(data);
        // console.log(res);
        if (res.code == 401) {
          this.$router.push("/login");
        }else if (res.code == 200) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.rejectVisible=false
          this.getDepartmentNotPage()
          this.rejectVisibleClose()
        } else{
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      }else{
        this.$message({
          message: '请选择驳回原因',
          type: "error",
        });
      }
    },
    fillClick(row) {
      this.bascId=row.id
      this.basbasePeriodTime=row.basePeriodTime
      this.basStatus=row.status7
      this.fillTitle=row.name+row.basePeriodTime+'绩效填报审核'
      this.btnShow=true
      this.FillingVisible=true
      this.disabled=false
      this.Performance.nightVal=row.nightDutyAllowance//夜班费
      this.Performance.twoCanVal=row.cancerScreen//两癌筛查
      this.Performance.medifuVal=row.medicalInsuranceFund//医保基金
      this.Performance.bonsuVal=row.bonusSubsidy//奖金补助
      this.Performance.otherSpecial=row.otherSpecial//体检专项(体检科)
      this.Performance.perexpVal=row.personnelExpenditure//人员列支
      this.Performance.checkup=row.checkup//体检专项
      this.Performance.painreVal=row.painfulDelivery//阵痛分娩奖励
      this.Performance.qucopeVal=row.qualityControl//质控奖罚
      this.Performance.exlabexp=row.outCheckExpend//外院化验支出
      this.Performance.refexlabt=row.outCheckIncome//外院化验收入
      this.Performance.other=row.other//其他
    },
    // 查看详情
    lookClick(row){
      this.fillTitle=row.name+row.basePeriodTime+'绩效填报审核'
      this.btnShow=false
      this.FillingVisible=true
      this.disabled=true
      this.Performance.nightVal=row.nightDutyAllowance//夜班费
      this.Performance.twoCanVal=row.cancerScreen//两癌筛查
      this.Performance.medifuVal=row.medicalInsuranceFund//医保基金
      this.Performance.bonsuVal=row.bonusSubsidy//奖金补助
      this.Performance.otherSpecial=row.otherSpecial//体检专项(体检科)
      this.Performance.perexpVal=row.personnelExpenditure//人员列支
      this.Performance.checkup=row.checkup//体检专项
      this.Performance.painreVal=row.painfulDelivery//阵痛分娩奖励
      this.Performance.qucopeVal=row.qualityControl//质控奖罚
      this.Performance.exlabexp=row.outCheckExpend//外院化验支出
      this.Performance.refexlabt=row.outCheckIncome//外院化验收入
      this.Performance.other=row.other//其他
    },
    // 通过
    refillClick(row){
      this.$confirm('审核通过后不能撤回~',  {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let data = {
          id: row.id, //id
          departmentId: row.departmentId, //科室id
          outCheckExpend:row.outCheckExpend,//外院化验支出
          outCheckIncome:row.outCheckIncome,//外院化验收入
        };
        let { data: res } = await this.$axios.approved(data);
        // console.log(res);
        if (res.code == 401) {
          this.$router.push("/login");
        }else if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '审核通过成功!'
          });
          this.getDepartmentNotPage()
        } else{
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消审核'
        });
      });
    },
    reasonClick(row){
      // console.log(row)
      if(row.name=='妇产科'){
        this.rejectOption=['夜班费', '质控奖罚', '两癌筛查','医保基金','奖金补助','人员列支','体检专项(体检科)','体检专项','外院化验收入','外院化验支出','阵痛分娩奖励', '其他']
      }else{
        this.rejectOption=['夜班费', '质控奖罚', '两癌筛查','医保基金','奖金补助','人员列支','体检专项(体检科)','体检专项','外院化验收入','外院化验支出', '其他']
      }


      this.checkDisab=true
      this.rejectVisible=true
      this.rejectTitle='驳回原因'
      this.checkedCities=row.reasonForRejection.split(',')
    },
    // 驳回
    rejectClick(row){
      // console.log(row)
      this.rejName=row.name
      if(row.name=='妇产科'){
        this.rejectOption=['夜班费', '质控奖罚', '两癌筛查','医保基金','奖金补助','人员列支','体检专项(体检科)','体检专项','外院化验收入','外院化验支出','阵痛分娩奖励', '其他']
      }else{
        this.rejectOption=['夜班费', '质控奖罚', '两癌筛查','医保基金','奖金补助','人员列支','体检专项(体检科)','体检专项','外院化验收入','外院化验支出', '其他']
      }
      this.bascId=row.id
      this.rejectVisible=true
      this.rejectTitle='请选择驳回原因'
      this.checkedCities=[]
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDepartmentNotPage();
    },
    //   绩效填报审核列表
    async getDepartmentNotPage() {
      let data = {
        basePeriodTime:this.startTime,//时间
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示数量
        pageType:'AUDIT',//审核列表
        departmentId:this.typeValue,
    };
      let { data: res } = await this.$axios.findCurrentYearAndMonthInformation(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.DeplisttableData = res.rows;
        this.total = res.total;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    onsubmit() {
      this.$refs.PerformanceRef.validate(async(valid) => {
        if (valid) {
          let data = _qs.stringify({
            id: this.bascId,//主键
            basePeriodTime:this.basbasePeriodTime,//年份
            departmentId:window.sessionStorage.getItem("departmentId"),//科室id
            status7:this.basStatus,
            outCheckIncome:this.Performance.refexlabt,//外院化验收入
            outCheckExpend:this.Performance.exlabexp,//外院化验支出
            nightDutyAllowance:this.Performance.nightVal,//夜班费
            painfulDelivery:this.Performance.painreVal,//阵痛分娩奖励
            qualityControl:this.Performance.qucopeVal,//质控奖罚
            cancerScreen:this.Performance.twoCanVal,//两癌筛查
            medicalInsuranceFund:this.Performance.medifuVal,//医保基金
            bonusSubsidy:this.Performance.bonsuVal,//奖金补助
            otherSpecial:this.Performance.otherSpecial,//体检专项(体检科)
            personnelExpenditure:this.Performance.perexpVal,//人员列支
            checkup:this.Performance.checkup,//体检专项
            other:this.Performance.other,//其他
          });
          let { data: res } = await this.$axios.perdatRep(data);
          // console.log(res);
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getDepartmentNotPage()
            this.FillingVisible=false
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tabBig{
  width: 500px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  /deep/ .el-input__inner{
    border:none!important;
  }
  .line{
    height: 40px;
    border: 1px solid #ccc;
  }
  .boxTwo,.boxOne{
    width: 50%;
  }
}

/deep/ .el-form-item.hosform{
  margin-bottom: 0px!important;
}

.checRev /deep/ .el-checkbox{
  margin-bottom: 20px;
}

.twoForm {
  float: right;
  display: flex;
}

/deep/ .el-select.xiangType .el-input--suffix {
  width: 150px !important;
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

.btnSize button{
  width: 120px;
  margin-top: 20px;
}

// 取消开始时间和结束时间的×符号
/deep/ .twoForm .timClas .el-input__suffix{
  display: none!important;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #ffffff;
}
</style>
